import { lazy } from "react"

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard"
import ScoutingCommonLayout from "layout/scouting/CommonLayout"
import Loadable from "components/Loadable"

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")))

// ==============================|| AUTH ROUTES ||============================== //

const ScoutingLoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <ScoutingCommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "",
          element: <AuthLogin logoText={"scouting"} />
        },
        {
          path: "login",
          element: <AuthLogin logoText={"scouting"} />
        }
      ]
    }
  ]
}

export default ScoutingLoginRoutes
