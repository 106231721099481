import { createContext, useEffect, useReducer, ReactElement } from "react"
import { Chance } from "chance"
import jwtDecode from "jwt-decode"
import { LOGIN, LOGOUT } from "store/reducers/actions"
import authReducer from "store/reducers/auth"
import Loader from "components/Loader"
import axios from "utils/axios"
import { KeyedObject } from "types/root"
import { AuthProps, JWTContextType, UserProfile } from "types/auth"

const chance = new Chance()

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  scoutingAccess: false,
  offersAccess: false,
  adminAccess: false,
  user: null
}

export const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken)
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
  } else {
    localStorage.removeItem("serviceToken")
    delete axios.defaults.headers.common.Authorization
  }
}

const verifyToken = async (serviceToken: string) => {
  if (!serviceToken) {
    return false
  }
  const decoded: KeyedObject = jwtDecode(serviceToken)

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */

  let isValid = decoded.exp > Date.now() / 1000

  if (isValid) {
    setSession(serviceToken)
  } else {
    const tokenResponse = await axios.post("/refresh/", {}, { withCredentials: true })
    if (tokenResponse?.data?.token) {
      const token = tokenResponse.data.token
      setSession(token)
      isValid = true
    } else {
      setSession(null)
    }
  }

  return isValid
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem("serviceToken")

        if (serviceToken) {
          const isTokenValid = await verifyToken(serviceToken)
          if (!isTokenValid) {
            dispatch({
              type: LOGOUT
            })
            return
          }

          const response = await axios.get("/me/")
          const user = response.data as UserProfile

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              scoutingAccess: user.scouting_access,
              offersAccess: user.offers_access,
              adminAccess: user.admin_access,
              user
            }
          })
        } else {
          dispatch({
            type: LOGOUT
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: LOGOUT
        })
      }
    }

    init().then()
  }, [])

  const login = async (username: string, password: string) => {
    const response = await axios.post("/login/", { username, password }, { withCredentials: true })

    const { serviceToken, user } = { serviceToken: response.data.token, user: response.data as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })
  }

  const sendCodeValidation = async (username: string, password: string) => {
    const ipResp = await axios("https://api.ipify.org/?format=json", { method: "GET" })

    const response = await axios("/users-code-validation/send_validation/?use_credentials=1", {
      method: "POST",
      data: { email: username, username, password, ip_address: ipResp.data.ip }
    })

    if (!response.data.success) {
      return false
    }

    if (response.data.override_code_validation) {
      await login(username, password)
    }

    return true
  }

  const validateEmailAddress = async (email: string, code: string) => {
    const response = await axios("/users-code-validation/validate_email_address/", {
      method: "POST",
      data: { email, code }
    })

    if (!response.data.success) {
      return false
    }

    return true
  }

  const loginSpotify = async (code: string) => {
    const response = await axios.post(`/spotify/?code=${code}`, null, {
      withCredentials: true
    })

    const { serviceToken, user } = { serviceToken: response.data.token, user: response.data as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })
  }

  const logout = () => {
    setSession(null)
    dispatch({ type: LOGOUT })
  }

  const updateProfile = () => {}

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        loginSpotify,
        sendCodeValidation,
        validateEmailAddress,
        logout,
        updateProfile
      }}
    >
      {children}
    </JWTContext.Provider>
  )
}

export default JWTContext
